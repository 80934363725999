export default function HRdeleteObjective(){
	return `mutation HRDeleteObjectives(
		$objective_id: String!
		$appraisee_id: String!
	) {
		hrDeleteObjectives(
			objective_id: $objective_id
			appraisee_id: $appraisee_id
		)
	}`;
}
