import PMS from "@global/components/PMS";
import Requester from '@global/components/Requester';
import HRFinalFeedback from '@global/query/HRFinalFeedback';
import managerFinalFeedback from '@global/query/managerFinalFeedback';

const Final = {
	get: async (id) => {
		try {
			let query = 'performance {_9box {year pos} objectives {year total_score objectives {_id objective goal score results title weight approved score comments attachments}} principles {year total_score principles {_id principle_pt score comments description_pt score comments attachments}} general_score {year score result} final_feedback {year manager_comments appraisee_comments}} flows {year flows {type status}}';
			let user = await PMS.User.getUserQuery(query, null, id);

			if(user) {
        return {
          _9box: user.performance._9box.find(filterYear) || {},
          final: user.performance.final_feedback.find(filterYear) || {},
          objectives: user.performance.objectives.find(filterYear) || {},
          principles: user.performance.principles.find(filterYear) || {},
          general_score: user.performance.general_score.find(filterYear) || {},
        };
			}
			else {
				return {};
			}
		} catch(err) {
			console.log(err);
			return {};
		}
	},
	edit: async(final, appraisal_id) => {
		try{
			let action = PMS.Cognito.isHR() ? HRFinalFeedback : managerFinalFeedback;

			let {data} = await Requester({
				data: {
					query: action(),
					variables: {
						appraisal_id,
						manager_comments: final.managerComment,
						appraisee_comments: final.employeeComment,
					}
				}
			});

			return data.hrFinalFeedback || data.managerFinalFeedback;

		} catch(err){
			return false;
		}
	},
	finishFeedback: async(final, userId) => {
		try{
			let result = await Final.edit(final, userId);

			if(result){
				await PMS.Flows.getFinishFlowQuery("getCollaborator", userId, PMS.Flows.final);
				return true;
			}
			else{
				return false;
			}
		} catch(err) {
			return false;
		}
	},
}

const filterYear = (item) => {
  return item.year === PMS.Dates.currentYear;
}

export default Final;
