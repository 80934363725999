const managerGetCollaborator = (query) => {
	return `query GetManagerCollaborator(
		$id: String!
	) {
		getManagerCollaborator(_id: $id) {
			${query}
		}
	}`;
}

export default managerGetCollaborator;
