import PMS from "@global/components/PMS";
import Requester from "@global/components/Requester";
import checkEmail from '@global/query/checkEmail';
import getCollaborator from '@global/query/getCollaborator';
import listCollaborator from '@global/query/listCollaborator';
import listPhaseCollaborator from '@global/query/listPhaseCollaborator';
import managerGetCollaborator from '@global/query/managerGetCollaborator';
import managerListCollaborator from '@global/query/managerListCollaborator';
import managerPhaseListCollaborator from '@global/query/managerListPhaseCollaborator';
import myProfile from '@global/query/myProfile';

const User = {
	getId: () => {
		return localStorage.getItem("_id");
	},
	setId: (_id) => {
		localStorage.setItem("_id", _id);
		return;
	},
	getEmail: () => {
		return localStorage.getItem("email");
	},
	setEmail: (email) => {
		localStorage.setItem("email", email);
		return;
	},
	getUserQuery: async(query, profile, id) => {
		try{
			let action = myProfile;

			if(PMS.Cognito.isHR()){
				action = getCollaborator;
			}
			else if(PMS.Cognito.isManager() && profile !== "myProfile"){
				action = managerGetCollaborator;
			}

			let res = await Requester({
				data: {
					query: action(query),
					variables: {
						id: id || PMS.User.getId()
					}
				}
			});

			return res.data.getCollaborator || res.data.myProfile || res.data.getManagerCollaborator;
		}
		catch(err){
			return {};
		}
	},

	listPhaseUsersQuery: async(query) => {
		try{
			if(PMS.Cognito.isHR()){
				let isEnding = false;
				let users = [];
				let page = 0;

				while(!isEnding){
					let res = await Requester({
						data: {
							query: listPhaseCollaborator(query),
							variables: {
								page,
								size: 50
							}
						}
					});
					users.push(...res.data.listPhaseCollaborator.collaborators);
					isEnding = res.data.listPhaseCollaborator.isEnding;
					page++;
				}

				return users.filter((item) => {
					if(item.profile && item.profile.current){
						return item.profile.current.status;
					}
					else{
						return item;
					}
				});
			}
			else if(PMS.Cognito.isManager()){
				let res = await Requester({
					data: {
						query: managerPhaseListCollaborator(query),
					}
				});

				return res.data.managerListPhaseCollaborator.collaborators;
			}
			else{
				return [];
			}
		}
		catch(err){
			return {};
		}
	},
	listUsersQuery: async(query) => {
		try{
			if(PMS.Cognito.isHR()){
				let isEnding = false;
				let users = [];
				let page = 0;

				while(!isEnding){
					let res = await Requester({
						data: {
							query: listCollaborator(query),
							variables: {
								page,
								limit: 50
							}
						}
					});
					users.push(...res.data.listCollaborator.collaborators);
					isEnding = res.data.listCollaborator.isEnding;
					page++;
				}

				return users.filter((item) => {
					if(item.profile && item.profile.current){
						return item.profile.current.status;
					}
					else{
						return item;
					}
				});
			}
			else if(PMS.Cognito.isManager()){
				let res = await Requester({
					data: {
						query: managerListCollaborator(query),
					}
				});

				return res.data.listManagerCollaborator;
			}
			else{
				return [];
			}
		}
		catch(err){
			return {};
		}
	},
	checkEmailAvailability: async(email) => {
		let res = await Requester({
			data: {
				query: checkEmail(),
				variables: {email}
			}
		});

		return res.data.isEmailAvailable;
	},
}

export default User;
