export default function managerEditObjective(){
	return `mutation ManagerEditObjectives(
		$appraisee_id: String!
		$objective_id: String
		$objective: String
		$weight: Float
		$goal: String
		$title: String
		$approved: Boolean
	) {
		managerEditObjectives(
			appraisee_id: $appraisee_id
			objective_id: $objective_id
			objective: $objective
			weight: $weight
			goal: $goal
			title: $title
			approved: $approved
		)
	}`;
}
