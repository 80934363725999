import React, { lazy, Suspense } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import PrivateRoute from "@global/components/PrivateRoute/";
import PMS from "@global/components/PMS";
import { TourRoot } from "react-rtg";
const MainPageHR = lazy(() => import("./hr/pages/main"));
const MainPageManager = lazy(() => import("./manager/pages/main"));
const MainPageCollaborator = lazy(() => import("./collaborator/pages/main"));
const Login = lazy(() => import("./login/pages/login"));

const App = () => {
	PMS.Initialize();

	const getCurrentInterface = () => {
		let group = PMS.Cognito.get();
		if (/(head|manager)/.test(group)) {
			return MainPageManager;
		}
		else if (/human-resources/.test(group)) {
			return MainPageHR;
		}
		else {
			return MainPageCollaborator;
		}
	}

	return <TourRoot>
		<BrowserRouter>
			<Suspense fallback={<div />}>
				<Switch>
					<Route exact path="/login" component={Login} />
					<Route path="/recovery" component={Login} />
					<Route path="/recovery/:token" component={Login} />
					<PrivateRoute path="/" component={getCurrentInterface()} />
				</Switch>
			</Suspense>
		</BrowserRouter>
	</TourRoot>
}

export default App;
