const managerListCollaborator = (query) => {
	return `query ManagerListPhaseCollaborator {
		managerListPhaseCollaborator {
			isEnding
			collaborators{
				${query}
			}
		}
	}`;
}

export default managerListCollaborator;
