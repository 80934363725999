const checkEmail = () => {
	return `query IsEmailAvailable(
    $email: String!
  ) {
    isEmailAvailable(
      email:$email
    )
  }`;
}

export default checkEmail;
