import PMS from "@global/components/PMS";
import Requester from "@global/components/Requester";
import AppraisalObj from "@global/query/AppraisalObj";
import AppraisalPrinc from "@global/query/AppraisalPrinc";
import deleteObjective from "@global/query/deleteObjective";
import editObjective from "@global/query/editObjective";
import HRAppraisalObj from "@global/query/HRAppraisalObj";
import HRAppraisalPrinc from "@global/query/HRAppraisalPrinc";
import HRdeleteObjective from "@global/query/HRdeleteObjective";
import HReditObjective from "@global/query/HReditObjective";
import HRSelfAppraisalObj from "@global/query/HRSelfAppraisalObj";
import HRSelfAppraisalPrinc from "@global/query/HRSelfAppraisalPrinc";
import managerDeleteObjective from "@global/query/managerDeleteObjective";
import managerEditObjective from "@global/query/managerEditObjective";
import selfAppraisalObj from "@global/query/selfAppraisalObj";
import selfAppraisalPrinc from "@global/query/selfAppraisalPrinc";
import { message } from "antd";
import { I18n } from "aws-amplify";

const Objectives = {
  get: async (id, profile, type) => {
    try {
      let query;
      query =
        type === "SelfAppraisal"
          ? "performance {objectives {year total_score objectives {_id objective goal score results title weight approved self_score self_comments self_attachments}} principles {year total_score principles {_id principle_pt score comments description_pt self_score self_comments self_attachments}} general_score {year score result}} flows {year flows {type status pendencies}}"
          : "performance {objectives {year total_score objectives {_id objective goal score results title weight approved score comments attachments}} principles {year total_score principles {_id principle_pt score comments description_pt score comments attachments}} general_score {year score result}} flows {year flows {type status pendencies}}";
      let user = await PMS.User.getUserQuery(query, profile, id);
      if (user) {
        let historyObj = JSON.parse(
          localStorage.getItem("historyObj" + type) || "[]"
        );
        let historyPrinc = JSON.parse(
          localStorage.getItem("historyPrinc" + type) || "[]"
        );
        let flows = user.flows;
        let data = user.performance.objectives;
        let principles = user.performance.principles;
        let general_score = user.performance.general_score;

        let currentData = (
          data.find((item) => {
            return +item.year === PMS.Dates.currentYear;
          }) || { objectives: [] }
        ).objectives;

        let currentPrinciples = (
          principles.find((item) => {
            return +item.year === PMS.Dates.currentYear;
          }) || { principles: [] }
        ).principles;

        for (let i = 0; i < currentData.length; i++) {
          if (currentData[i].self_score !== undefined) {
            currentData[i].score = currentData[i].self_score;
            delete currentData[i].self_score;
          }
          if (currentData[i].self_comments !== undefined) {
            currentData[i].comments = currentData[i].self_comments;
            delete currentData[i].self_comments;
          }
          if (currentData[i].self_attachments !== undefined) {
            currentData[i].attachments = currentData[i].self_attachments;
            delete currentData[i].self_attachments;
          }
        }

        currentData = currentData.map((item) => {
          for (let i = 0; i < historyObj.length; i++) {
            if (item._id === historyObj[i]._id) {
              item = {
                ...item,
                ...historyObj[i],
              };
              break;
            }
          }

          return item;
        });

        for (let j = 0; j < currentPrinciples.length; j++) {
          if (currentPrinciples[j].self_score !== undefined) {
            currentPrinciples[j].score = currentPrinciples[j].self_score;
            delete currentPrinciples[j].self_score;
          }
          if (currentPrinciples[j].self_comments !== undefined) {
            currentPrinciples[j].comments = currentPrinciples[j].self_comments;
            delete currentPrinciples[j].self_comments;
          }
          if (currentPrinciples[j].self_attachments !== undefined) {
            currentPrinciples[j].attachments =
              currentPrinciples[j].self_attachments;
            delete currentPrinciples[j].self_attachments;
          }
        }

        currentPrinciples = currentPrinciples.map((item) => {
          for (let i = 0; i < historyPrinc.length; i++) {
            if (item._id === historyPrinc[i]._id) {
              item = {
                ...item,
                ...historyPrinc[i],
              };
              break;
            }
          }

          return item;
        });

        return {
          keys: getAvailableYears(principles),
          data,
          flows,
          principles,
          currentData,
          general_score,
          currentPrinciples,
        };
      } else {
        return returnEmptyResponse();
      }
    } catch (err) {
      return returnEmptyResponse();
    }
  },
  edit2: async (obj, appraisee_id) => {
    try {
      let query;
      let variables = {
        appraisee_id,
        objective: obj.objective,
        weight: obj.weight,
        goal: obj.goal,
        title: obj.title,
        approved: obj.approved,
      };
      if (!!appraisee_id) {
        variables.objective_id = obj._id;
        if (PMS.Cognito.isHR()) {
          query = HReditObjective;
        } else if (PMS.Cognito.isManager()) {
          query = managerEditObjective;
        } else {
          query = editObjective;
        }
      } else {
        variables._id = obj._id;
        query = editObjective;
      }
      let { data } = await Requester({
        data: {
          query: query(),
          variables,
        },
      });
      return Object.values(data)[0]
    } catch (error) {
      message.error(I18n.get("Error editing Objective!"));
    }
  },
  edit: async (obj, appraisee_id, create, noSuccess) => {
    try {
      let action =
        PMS.Cognito.isHR() && appraisee_id
          ? HReditObjective
          : PMS.Cognito.isManager() && appraisee_id
          ? managerEditObjective
          : editObjective;

      let query = {
        appraisee_id,
        objective: obj.objective,
        weight: obj.weight,
        goal: obj.goal,
        title: obj.title,
        approved: obj.approved,
      };
      if (!appraisee_id) {
        query._id = obj._id;
      } else {
        query.objective_id = obj._id;
      }
      let { data } = await Requester({
        data: {
          query: action(),
          variables: query,
        },
      });

      let result =
        data.hrEditObjectives ||
        data.managerEditObjectives ||
        data.writeObjectives;
      if (result) {
        if (!noSuccess) {
          message.success(
            I18n.get(
              "Objective " + (create ? "created" : "edited") + " successfully!"
            )
          );
        }
        return result;
      } else {
        if (!noSuccess) {
          message.error(
            I18n.get(
              "Error " + (create ? "creating" : "editing") + " Objectiv!"
            )
          );
        }
        return false;
      }
    } catch (err) {
      if (!noSuccess) {
        message.error(I18n.get("Error editing Objective!"));
      }
      return false;
    }
  },
  create: async (pdp, appraisee_id, noSuccess) => {
    delete pdp._id;

    let result = await Objectives.edit(pdp, appraisee_id, true, noSuccess);

    return result;
  },
  delete: async (objective_id, appraisee_id) => {
    let action =
      PMS.Cognito.isHR() && appraisee_id
        ? HRdeleteObjective
        : PMS.Cognito.isManager() && appraisee_id
        ? managerDeleteObjective
        : deleteObjective;

    let result = await Requester({
      data: {
        query: action(),
        variables: { objective_id, appraisee_id },
      },
    });
    return result;
  },
  editDataById: (_id, value, key, data, type, phase) => {
    let result = data.map((item) => {
      if (item._id === _id) {
        if (key === "attachments") {
          item[key] = item[key] ? [...item[key], value] : [value];
        } else {
          item[key] = value;
        }
      }
      return item;
    });

    localStorage.setItem("history" + phase + type, JSON.stringify(result));

    return result;
  },
  removeDataById: (_id, key, data, type, phase) => {
    let result = data.map((item) => {
      if (item._id === _id) {
        item.attachments = item.attachments.filter((obj) => {
          return obj !== key;
        });
      }
      return item;
    });

    localStorage.setItem("history" + phase + type, JSON.stringify(result));

    return result;
  },
  getAppraisalQuery: async (data, appraisee_id, type, phase) => {
    try {
      let action;
      if (phase === PMS.Flows.self) {
        action = type === "objectives" ? selfAppraisalObj : selfAppraisalPrinc;
        if (appraisee_id) {
          action =
            type === "objectives" ? HRSelfAppraisalObj : HRSelfAppraisalPrinc;
        }
      } else {
        action = type === "objectives" ? AppraisalObj : AppraisalPrinc;
        if (appraisee_id) {
          action = type === "objectives" ? HRAppraisalObj : HRAppraisalPrinc;
        }
      }

      let res = await Requester({
        data: {
          query: action(),
          variables: {
            [type]: sanitizeFields(data),
            appraisee_id,
          },
        },
      });

      let result =
        res.data.selfAppraisal ||
        res.data.hrSelfAppraisal ||
        res.data.managerAppraisal ||
        res.data.hrAppraisal;

      if (result === "Success") {
        return true;
      } else {
        return false;
      }
    } catch (err) {
      console.log(err);
      return false;
    }
  },
  finishDefinition: async (objs, userId) => {
    try {
      let result = await Promise.all(
        objs.map((item) => {
          return new Promise(async (resolve, reject) => {
            let res;
            if (item._id) {
              res = await Objectives.edit(item, userId, false, true);
            } else {
              res = await Objectives.create(item, userId, true);
            }
            if (res) {
              resolve();
            } else {
              reject();
            }
          });
        })
      );

      if (result) {
        await timeout();
        let profile = !userId ? "myProfile" : "getCollaborator";
        await PMS.Flows.getFinishFlowQuery(
          profile,
          userId,
          PMS.Flows.definition,
          "Objectives"
        );
        return true;
      } else {
        return false;
      }
    } catch (err) {
      return false;
    }
  },
  finishReview: async (objs, userId) => {
    try {
      let result = await Promise.all(
        objs.map((item) => {
          return new Promise(async (resolve, reject) => {
            let res;
            if (item._id) {
              res = await Objectives.edit(item, userId, false, true);
            } else {
              res = await Objectives.create(item, userId, true);
            }
            if (res) {
              resolve();
            } else {
              reject();
            }
          });
        })
      );

      if (result) {
        await timeout();
        await PMS.Flows.getFinishFlowQuery(
          "getCollaborator",
          userId,
          PMS.Flows.review,
          "Objectives"
        );
        return true;
      } else {
        return false;
      }
    } catch (err) {
      console.log(err);
      return false;
    }
  },
  finishSelfAppraisalObj: async (objectives, userId) => {
    try {
      let result = await Objectives.getAppraisalQuery(
        objectives,
        userId,
        "objectives",
        PMS.Flows.self
      );
      if (result) {
        await PMS.Flows.getFinishFlowQuery(
          "myProfile",
          userId,
          PMS.Flows.self,
          "Objectives"
        );
        Objectives.cleanHistoryObjSelfAppraisal();
        return true;
      } else {
        return false;
      }
    } catch (err) {
      console.log(err);
      return false;
    }
  },
  finishAppraisalObj: async (objectives, userId) => {
    try {
      let result = await Objectives.getAppraisalQuery(
        objectives,
        userId,
        "objectives",
        PMS.Flows.appraisal
      );

      if (result) {
        await PMS.Flows.getFinishFlowQuery(
          "getCollaborator",
          userId,
          PMS.Flows.appraisal,
          "Objectives"
        );
        Objectives.cleanHistoryObjAppraisal();
        return true;
      } else {
        return false;
      }
    } catch (err) {
      console.log(err);
      return false;
    }
  },
  finishSelfAppraisalPrinc: async (principles, userId) => {
    try {
      let result = await Objectives.getAppraisalQuery(
        principles,
        userId,
        "principles",
        PMS.Flows.self
      );

      if (result) {
        await PMS.Flows.getFinishFlowQuery(
          "myProfile",
          userId,
          PMS.Flows.self,
          "Principles"
        );
        Objectives.cleanHistoryPrincSelfAppraisal();
        return true;
      } else {
        return false;
      }
    } catch (err) {
      return false;
    }
  },
  finishAppraisalPrinc: async (principles, userId) => {
    try {
      let result = await Objectives.getAppraisalQuery(
        principles,
        userId,
        "principles",
        PMS.Flows.appraisal
      );

      if (result) {
        await PMS.Flows.getFinishFlowQuery(
          "getCollaborator",
          userId,
          PMS.Flows.appraisal,
          "Principles"
        );
        Objectives.cleanHistoryPrincAppraisal();
        return true;
      } else {
        return false;
      }
    } catch (err) {
      return false;
    }
  },
  validateDefinition: (data) => {
    let weight = 0;

    data.map((item) => {
      weight += +item.weight || 0;
    });

    return data.length >= 3 && data.length <= 6 && weight === 100;
  },
  validateReview: (data) => {
    return Objectives.validateDefinition(
      data.filter((item) => {
        return item.approved && item.title && item.objective && item.goal;
      })
    );
  },
  validateAppraisal: (data) => {
    let isValid = true;
    data.map((item) => {
      if (!item.comments) {
        isValid = false;
      }
    });

    return isValid;
  },
  getRequiredFields: async (data, type) => {
    if (type === "SelfAppraisal") {
      return [];
    } else {
      let required = [];

      for (let i = 0; i < data.length; i++) {
        required[i] = ["comments"];
      }

      return required;
    }
  },
  cleanHistoryObjSelfAppraisal: () => {
    localStorage.removeItem("historyObjSelfAppraisal");
  },
  cleanHistoryObjAppraisal: () => {
    localStorage.removeItem("historyObjAppraisal");
  },
  cleanHistoryPrincSelfAppraisal: () => {
    localStorage.removeItem("historyPrincSelfAppraisal");
  },
  cleanHistoryPrincAppraisal: () => {
    localStorage.removeItem("historyPrincAppraisal");
  },
};

const sanitizeFields = (data) => {
  return data.map((item) => {
    return {
      _id: item._id,
      score: item.score,
      attachments: item.attachments,
      comments: item.comments,
    };
  });
};

const returnEmptyResponse = () => {
  return {
    keys: [],
    data: [],
    flows: [],
    principles: [],
    currentData: [],
    general_score: [],
    currentPrinciples: [],
  };
};

const getAvailableYears = (data) => {
  let keys = [];
  let currentYear = new Date().getFullYear();
  for (let i = 0; i < data.length; i++) {
    if (!keys.includes(data[i].year)) {
      keys.push(data[i].year);
    }
  }
  if (!keys.includes(currentYear)) {
    keys.unshift(currentYear);
  }

  return keys.sort((a, b) => {
    return b - a;
  });
};

const timeout = () => {
  return new Promise((resolve) => setTimeout(resolve, 1000));
};

export default Objectives;
