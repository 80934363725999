const HRSelfAppraisalObj = () => {
	return `mutation hrSelfAppraisal(
		$appraisee_id: String
		$objectives: [AppraisalInput]
	) {
		hrSelfAppraisal(
			appraisee_id: $appraisee_id
			objectives: $objectives
		)
	}`;
}

export default HRSelfAppraisalObj;
