const HRSelfAppraisalPDP = () => {
	return `mutation hrSelfAppraisal(
		$appraisee_id: String
		$pdps:[PDPAppraisalInput]
	) {
		hrSelfAppraisal(
			appraisee_id: $appraisee_id
			pdps: $pdps
		)
	}`;
}

export default HRSelfAppraisalPDP;
