const HRAppraisalObj = () => {
	return `mutation hrAppraisal(
		$appraisee_id: String
		$objectives: [AppraisalInput]
	) {
		hrAppraisal(
			appraisee_id: $appraisee_id
			objectives: $objectives
		)
	}`;
}

export default HRAppraisalObj;
