import {I18n} from "aws-amplify";
import {message, Modal} from "antd";
import Requester from "@global/components/Requester";
import getCollaboratorsBySubgroup from '@global/query/getCollaboratorsBySubgroup';
import saveCalibration from '@global/query/saveCalibration';
import saveSuccession from '@hr/query/HRSaveSuccession';
import listSuccession from '@global/query/listSuccession';
import listKeyPositions from '@global/query/listKeyPositions';
import addKeyPosition from '@global/query/addKeyPosition';
const {confirm} = Modal;

const Calibration = {
	get: async (subgroup, year, notFormated) => {
		try {
			let res = await Requester({
				data: {
					query: getCollaboratorsBySubgroup(),
					variables: {
						subgroup,
						year
					}
				}
			});

			let list = res.data.hrListCollaboratorSubGroup;

			if(list && !notFormated) {

				let data = {
					high: [],
					significant: [],
					contributor: []
				}
				for(let i = 0; i < list.length; i++){
					if(list[i].status){
						data[list[i].status].push(list[i]);
					}
				}

				return data;
			}
			else if(list){
				return list;
			}
			else {
				return returnEmptyResponse;
			}
		} catch(err) {
			return returnEmptyResponse;
		}
	},
	set: (item) => {
		if(item._id === getLastEdit()){
			setTimeout(() => {
				saveCalibrationProgress(item);
			}, 1000);
		}
		else{
			saveCalibrationProgress(item);;
		}
		setLastEdit(item._id);
	},
	getKeyPosition: async(subgroup) => {
		try{
			let res = await Requester({
				data: {
					query: listKeyPositions(),
					variables: {
						subgroup
					}
				}
			});

			return res.data.hrListKeyPositions || [];
		} catch(err){
			return [];
		}
	},
	setKeyPosition: async(subgroup, name) => {
		try{
			let res = await Requester({
				data: {
					query: addKeyPosition(),
					variables: {
						subgroup,
						name
					}
				}
			});
			if(res.data.hrEditKeyPosition){
				message.success(I18n.get("New position created successfully!"));
				return res.data.hrEditKeyPosition;
			}
			else{
				message.error(I18n.get("Error creating new position !"));
				return false;
			}
		} catch(err){
			message.error(I18n.get("Error creating new position !"));
		}
	},
	saveSuccession: async(subgroup, positions) => {
		try{
			let res = await Requester({
				data: {
					query: saveSuccession(),
					variables: {
						subgroup,
						positions
					}
				}
			});
			return true;

		} catch(err){
			return false;
		}
	},
	getSuccession: async(subgroup) => {
		try{
			let res = await Requester({
				data: {
					query: listSuccession(),
					variables: {
						subgroup
					}
				}
			});
			return res.data.hrSuccessionList || [];
		} catch(err){
			return false;
		}
	},
	finishCalibration: async(subgroup, positions, onSuccess) => {
		confirm({
				title: I18n.get("Finish calibration"),
				content: I18n.get("Really want to save these changes?"),
				okText: I18n.get("OK"),
				cancelText: I18n.get("Cancel"),
				onOk: () => {
					return new Promise(async(resolve) => {
						let result = await Calibration.saveSuccession(subgroup, positions);
						if(result){
							setTimeout(() => {
								Modal.success({
									title: I18n.get("Subgroup calibratd successfully!"),
									content: `${I18n.get("The calibration of subgroup")} ${subgroup} ${I18n.get("was successfully complete.")}`
								});
								onSuccess();
							}, 500);

						}
						else{
							setTimeout(() => {
								Modal.error({
									title: I18n.get("Error calibrating subgroup!"),
									content: I18n.get("Verify if filled correctly the informations!")
								});
							}, 500);
						}
						resolve();
					});
				}
			});
	}
}

const saveCalibrationProgress = (variables) => {
	Requester({
		data: {
			query: saveCalibration(),
			variables
		}
	});
}

const setLastEdit = (id) => {
	localStorage.setItem("lastCalibrationId", id);
}

const getLastEdit = () => {
	return localStorage.getItem("lastCalibrationId");
}

const returnEmptyResponse = {
	high: [],
	significant: [],
	contributor: []
}


export default Calibration;
