import 'antd/dist/antd.css';
import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter} from 'react-router-dom';
import App from './app';
import PMS from '@global/components/PMS';
import Amplify, {I18n, Cache, Storage} from 'aws-amplify';

Amplify.configure({
    Auth: {
        identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
        region: process.env.REACT_APP_REGION,
        userPoolId: process.env.REACT_APP_USER_POOL_ID,
        userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID,
        mandatorySignIn: false,
        authenticationFlowType: 'USER_PASSWORD_AUTH',
    },
    Storage: {
        AWSS3: {
            bucket: process.env.REACT_APP_BUCKET,
            region: process.env.REACT_APP_REGION
        }
    },
    API: {
        endpoints: [
            {
                name: 'FastApi',
                endpoint: process.env.REACT_APP_API_URL,
                custom_header: async ()=>( {
                    'Authorization': `Bearer ${(await Amplify.Auth.currentSession()).getIdToken().getJwtToken()}`
                })
            },
        ]
    }
});

let json = Cache.getItem(PMS.Consts.CONST_LANGUAGE_CACHE_NAME);

if (json) {
    I18n.putVocabularies(JSON.parse(json));
    I18n.setLanguage(PMS.Language.get());
} else {
    Storage.get(PMS.Consts.CONST_LANGUAGE_PATH, {download: true}).then(result => {
        let data = JSON.parse(result.Body);
        let lang = new Date();
        lang.setDate(lang.getDate() + PMS.Consts.CACHE_EXPIRES);
        Cache.setItem(PMS.Consts.CONST_LANGUAGE_CACHE_NAME, JSON.stringify(data), {expires: lang.getTime()});
        window.location.reload();
    }).catch(err => console.log(err));
}

ReactDOM.render(
    <BrowserRouter>
        <App/>
    </BrowserRouter>
    , document.getElementById('root'));
