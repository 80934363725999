import PMS from "@global/components/PMS";
import {message} from "antd";
import {I18n} from 'aws-amplify';
import Requester from "@global/components/Requester";
import selfAppraisalPDP from '@global/query/selfAppraisalPDP';
import HRSelfAppraisalPDP from '@global/query/HRSelfAppraisalPDP';
import AppraisalPDP from '@global/query/AppraisalPDP';
import HRAppraisalPDP from '@global/query/HRAppraisalPDP';
import HREditPDP from '@global/query/HREditPDP';
import managerEditPDP from '@global/query/managerEditPDP';
import editPDP from '@global/query/editPDP';
import HRdeletePDP from '@global/query/HRdeletePDP';
import managerDeletePDP from '@global/query/managerDeletePDP';
import deletePDP from '@global/query/deletePDP';

const PDP = {
	get: async (id, profile, type) => {
		try {
			let query;
			query = type === "SelfAppraisal" ? 'performance {pdp {_id year category what_develop how_develop date start_date end_date expected_result description title approved status self_finished self_finished_date self_comments self_attachments principle_id repproved_principle} principles {year principles {score _id principle_pt}}} flows {year flows {type status pendencies}}' : 'performance {pdp {_id year category what_develop how_develop date start_date end_date expected_result description title approved status finished finished_date comments attachments principle_id repproved_principle} principles {year principles {score _id principle_pt}}} flows {year flows {type status pendencies}}';
			let user = await PMS.User.getUserQuery(query, profile, id);

			if(user) {
				for(let i = 0; i < user.performance.pdp.length; i++){
					if(user.performance.pdp[i].self_finished !== undefined){
						user.performance.pdp[i].finished = user.performance.pdp[i].self_finished;
						delete user.performance.pdp[i].self_finished;
					}
					if(user.performance.pdp[i].self_finished_date !== undefined){
						user.performance.pdp[i].finished_date = user.performance.pdp[i].self_finished_date;
						delete user.performance.pdp[i].self_finished_date;
					}
					if(user.performance.pdp[i].self_comments !== undefined){
						user.performance.pdp[i].comments = user.performance.pdp[i].self_comments;
						delete user.performance.pdp[i].self_comments;
					}
					if(user.performance.pdp[i].self_attachments !== undefined){
						user.performance.pdp[i].attachments = user.performance.pdp[i].self_attachments;
						delete user.performance.pdp[i].self_attachments;
					}
				}

				let historyPDP = JSON.parse(localStorage.getItem("historyPDP" + type) || "[]");
				let data = user.performance.pdp;

				return {
					keys: getAvailableYears(data),
					data,
					currentData: data.filter((item) => {
						return +item.year === PMS.Dates.currentYear
					}).map((item) => {
						for(let i = 0; i < historyPDP.length; i++){
							if(item._id === historyPDP[i]._id){
								item = {
									...item,
									...historyPDP[i]
								}
								break;
							}
						}

						return item;
					}),
					repprovedPrinciples: PDP.getRepprovedPrinciples(user.performance.principles),
					quant: PDP.getRequiredPDPCount(user.performance),
					hasRepproved: PDP.hasRepproved(data, profile),
					flows: user.flows
				}
			}
			else {
				return returnEmptyResponse();
			}
		} catch(err) {
			return returnEmptyResponse();
		}
	},
	edit: async(pdp, appraisee_id, create, current_year, noSuccess) => {
		try{
			let action = (PMS.Cognito.isHR() && appraisee_id ? HREditPDP :  PMS.Cognito.isManager() && appraisee_id ? managerEditPDP : editPDP);

			let {data} = await Requester({
				data: {
					query: action(),
					variables: {
						appraisee_id,
						_id: pdp._id,
						what_develop: pdp.what_develop,
						description: pdp.description,
						start_date: pdp.start_date,
						end_date: pdp.end_date,
						title: pdp.title,
						category: pdp.category,
						principle_id: pdp.principle_id,
						repproved_principle: pdp.repproved_principle,
						approved: pdp.approved,
						current_year,
					}
				}
			});

			let result = data.hrEditPDP || data.managerEditPDP || data.writePDP;
			if(result){
				if (!noSuccess) {
					message.success(I18n.get("PDP " + (create ? "created" : "edited") + " successfully!"));
				}
				return result;
			}
			else{
				if (!noSuccess) {
					message.error(I18n.get("Error " + (create ? "creating" : "editing") + " PDP!"));
				}
				return false;
			}
		} catch(err){
			if (!noSuccess) {
				message.error(I18n.get("Error editing PDP!"));
			}
			return false;
		}
	},
	create: async(pdp, appraisee_id, year, noSuccess) => {
		delete pdp._id;

		let result = await PDP.edit(pdp, appraisee_id, true, year, noSuccess);

		return result;
	},
	delete: async (_id, appraisee_id) => {
		let action = (PMS.Cognito.isHR() && appraisee_id ? HRdeletePDP :  PMS.Cognito.isManager() && appraisee_id ? managerDeletePDP : deletePDP);

		let result = await Requester({
			data: {
				query: action(),
				variables: {_id, appraisee_id}
			}
		});
		return result;
	},
	getRepprovedPrinciples: (principles) => {
		if(principles){
			localStorage.setItem("lastYearPrinciples", JSON.stringify(principles));
		}
		else{
			principles = JSON.parse(localStorage.getItem("lastYearPrinciples"));
		}

		let lastYearPrinciples = principles.find((item) => {
			return +item.year === PMS.Dates.pastYear;
		});

		if(lastYearPrinciples){
			let repprovedPrinciples = lastYearPrinciples.principles.filter((item) => {
				return item.score !== null && item.score <= PMS.Config.minScoreRepprovedPrinciple;
			});

			return repprovedPrinciples;
		}
		else {
			return [];
		}
	},
	getRequiredPDPCount: (data) => {
		let currentData = data.pdp.filter((item) => {
			return +item.year === PMS.Dates.currentYear
		});
		let repprovedPrinciplesCount = PDP.getRepprovedPrinciples(data.principles).length;

		return currentData.length > repprovedPrinciplesCount ? currentData.length : repprovedPrinciplesCount
	},
	hasRepproved: (data, profile) => {
		let currentData = data.filter((item) => {
			return +item.year === PMS.Dates.currentYear;
		}) || [];

		if(PMS.Cognito.isHR()){
			return currentData.filter((item) => {
				return item.approved || item.approved === null
			}).length !== currentData.length
		}
		else if(PMS.Cognito.isManager()){
			return currentData.filter((item) => {
				return item.approved || item.approved === null
			}).length !== currentData.length && profile !== "myProfile"
		}
	},
	editDataById: (_id, value, key, data, type) => {
		let result = data.map((item) => {
			if(item._id === _id) {
				item[key] = value;
			}
			return item;
		});

		localStorage.setItem(("historyPDP" + type), JSON.stringify(result));

		return  result;
	},
	removeDataById: (_id, key, data, type) => {
		let result = data.map((item) => {
			if(item._id === _id) {
				item.attachments = item.attachments.filter((pdp) => {
					return pdp !== key;
				})
			}
			return item;
		});

		localStorage.setItem(("historyPDP" + type), JSON.stringify(result));

		return result;
	},
	getAppraisalQuery: async(pdps, appraisee_id, type) => {
		try{
			let action = type === PMS.Flows.self ? selfAppraisalPDP : AppraisalPDP;
			if(appraisee_id){
				action = type === PMS.Flows.self ? HRSelfAppraisalPDP : HRAppraisalPDP;
			}

			let {data} = await Requester({
				data: {
					query: action(),
					variables: {
						pdps: sanitizeFields(pdps),
						appraisee_id
					}
				}
			});

			let result = data.selfAppraisal || data.hrSelfAppraisal || data.managerAppraisal || data.hrAppraisal;

			if(result === "Success"){
				return true;
			}
			else{
				return false;
			}
		} catch(err) {
			return false;
		}
	},
	finishDefinition: async(pdps, userId) => {
		try{
			let result = await Promise.all(pdps.map((item) => {
				return new Promise(async(resolve, reject) => {
					let res;
					if (item._id) {
						res = await PDP.edit(item, userId, false, undefined, true);
					} else {
						res = await PDP.create(item, userId, undefined, true);
					}
					if (res) {
						resolve();
					} else {
						reject();
					}
				})
			}));

			if (result) {
				timeout();
				let profile = !userId ? 'myProfile' : 'getCollaborator';
				await PMS.Flows.getFinishFlowQuery(profile, userId, PMS.Flows.definition, "PDP");
				return true;
			} else {
				return false;
			}
		} catch(err) {
			return false;
		}
	},
	finishReview: async(pdps, userId) => {
		try{
			let result = await Promise.all(pdps.map((item) => {
				return new Promise(async(resolve, reject) => {
					let res;
					if (item._id) {
						res = await PDP.edit(item, userId, false, undefined, true);
					} else {
						res = await PDP.create(item, userId, undefined, true);
					}
					if (res) {
						resolve();
					} else {
						reject();
					}
				})
			}));

			if (result) {
				timeout();
				await PMS.Flows.getFinishFlowQuery('getCollaborator', userId, PMS.Flows.review, "PDP");
				return true;
			} else {
				return false;
			}
		} catch(err) {
			return false;
		}
	},
	finishSelfAppraisal: async(pdps, userId) => {
		try{
			let result = await PDP.getAppraisalQuery(pdps, userId, PMS.Flows.self)
			if(result){
				await PMS.Flows.getFinishFlowQuery("myProfile", userId, PMS.Flows.self, "PDP");
				PDP.cleanHistoryPDPSelfAppraisal();
				return true;
			}
			else{
				return false;
			}
		} catch(err) {
			return false;
		}
	},
	finishAppraisal: async(pdps, userId) => {
		try{
			let result = await PDP.getAppraisalQuery(pdps, userId, PMS.Flows.appraisal);

			if(result){
				await PMS.Flows.getFinishFlowQuery("getCollaborator", userId, PMS.Flows.appraisal, "PDP");
				PDP.cleanHistoryPDPAppraisal();
				return true;
			}
			else{
				return false;
			}
		} catch(err) {
			return false;
		}
	},
	validateDefinition: (data) => {
		return data.filter((item) => !!item.title).length >= PDP.getRepprovedPrinciples().length;
	},
	validateReview: (data) => {
		return data.filter((item) => item.approved && item.title && item.what_develop && item.description).length >= PDP.getRepprovedPrinciples().length && data.filter((item) => item.approved || item.approved === false).length === data.length;
	},
	validateAppraisal: (data) => {
		let isValid = true;
		data.map((item) => {
			if(!item.finished  && !item.comments){
				isValid = false;
			}
		});

		return isValid;
	},
	getRequiredFields: async(data, type) => {
		if(type === "SelfAppraisal"){
			return [];
		}
		else{
			let required = [];

			for(let i = 0; i < data.length; i++){
				required[i] = [];
				if(!data[i].finished){
					required[i].push("comments");
				}
			}

			return required;
		}
	},
	cleanHistoryPDPSelfAppraisal: () => {
		localStorage.removeItem(("historyPDPSelfAppraisal"));
	},
	cleanHistoryPDPAppraisal: () => {
		localStorage.removeItem(("historyPDPAppraisal"));
	}
}

const sanitizeFields = (data) => {
	return data.map((item) => {
		return {
			_id: item._id,
			finished: item.finished,
			finished_date: item.finished_date,
			attachments: item.attachments,
			comments: item.comments
		}
	});
}

const getAvailableYears = (data) => {
	let keys = [];
	let currentYear = new Date().getFullYear();
	for(let i = 0; i < data.length; i++){
		if(!keys.includes(data[i].year)){
			keys.push(data[i].year);
		}
	}
	if(!keys.includes(currentYear)){
		keys.unshift(currentYear);
	}

	return keys.sort((a, b) => {
		return b - a
	});
}

const returnEmptyResponse = () => {
	return {
		keys: [],
		data: [],
		currentData: [],
		quant: 0
	}
}

const timeout = () => {
	return new Promise(resolve => setTimeout(resolve, 1000));
}

export default PDP;
