import React from 'react';
import {Route, Redirect} from "react-router-dom";
import {Cache} from 'aws-amplify';
import myProfile from '../../query/myProfile';
import Requester from '@global/components/Requester';
let Component;

class PrivateRoute extends React.Component {
	constructor(props){
		super(props);
		Component = props.component;
		this.state = {
			onboarding: true
		}
	}

	async componentWillMount(){
		let onboarding = await this.verifyOnboarding();
		if(!onboarding){
			window.location.href = "login";
		}
	}

	verifyOnboarding = async () => {
		return new Promise((resolve, reject) => {
			Requester({
				data: {
					query: myProfile('profile_complete'),
				},
				headers: {
					authorization: localStorage.getItem("accessToken")
				}
			}).then((res) => {
				resolve(res.data.myProfile.profile_complete);
			}).catch((err) => {
				console.log(err);
			});
		})
	}

	render(){
		return <Route {...this.props.rest} render={(props) => {
			return Cache.getItem("login") ? <Component {...this.props} /> : <Redirect to={{pathname: "/login"}} />
		}} />
	}
}

export default PrivateRoute;
