export default function HREditTemplate() {
    return `mutation HRSaveSuccessionV2(
        $year: Int
		$subgroup: String!
		$finish: Boolean
		$positions:[PositionV2Input]
		
	){
		hrSaveSuccessionV2(
		    year: $year
			finish: $finish
			subgroup: $subgroup
			positions: $positions
		)
	}`;
}
