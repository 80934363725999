const getCollaborator = (query) => {
	return `query GetCollaborator(
		$id: String
	) {
		getCollaborator(_id: $id) {
			${query}
		}
	}`;
}

export default getCollaborator;
