const getCollaboratorsBySubgroup = () => {
	return `query HRListCollaboratorSubGroup(
		$subgroup: String
		$year: Int
	) {
		hrListCollaboratorSubGroup(
			subgroup: $subgroup
			year: $year
		){
			_id
			picture
			name
			subgroup
			grade
			status
			old_status
			_9box
			mobility
			long_term
			medium_term
		}
	}`
}

export default getCollaboratorsBySubgroup;
