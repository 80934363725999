import PMS from "@global/components/PMS";
import * as moment from 'moment';
import Requester from "@global/components/Requester";
import getConfig from '@global/query/getConfig';

const Dates = {
	getDateFormat: () => {
		return (PMS.Language.get() === "pt-br" || PMS.Language.get() === "es") ? "DD/MM/YYYY" : "MM/DD/YYYY";
	},
	getDateLocale: (date) => {
		try {
			if(/T/ig.test(date)){
				date = date.split("T")[0] + "T23:59:59.000Z";
			}
			if(date){
				if(/\//.test(date)){
					return date;
				}
				date = new Date(date).toISOString().split("T")[0];
				if(isEnglishFormat()) {
					date = date.split("-");
					date = date[1] + "/" + date[2] + "/" + date[0];
				}
				else {
					date = date.split("-").reverse().join("/");
				}
			}
			return date;
		} catch(err) {
			return null;
		}
	},
	getDateISO: (date) => {
		if(date) {
			if(/T/ig.test(date)){
				return date;
			}
			else{
				if(isEnglishFormat()) {
					date = date.split("/");
					date = date[2] + "-" + date[0] + "-" + date[1];
				}
				else {
					date = date.split("/").reverse().join("-");
				}
				return date + "T23:59:59.000Z";
			}
		}
		else {
			return null;
		}
	},
	getShortDate: (date) => {
		if(isEnglishFormat()){
			return moment(date).format("MM/DD");
		}
		else{
			return moment(date).format("DD/MM");
		}
	},
	initialize: () => {
		Requester({
			data: {
				query: getConfig()
			}
		}).then(({data}) => {
			let currentYear = Dates.currentYear;
			localStorage.setItem('currentYear', data.getConfigs.year);
			localStorage.setItem('nextYear', data.getConfigs.year + 1);
			localStorage.setItem('pastYear', data.getConfigs.year - 1);

			if(currentYear !== data.getConfigs.year){
				window.location.reload();
			}
		}).catch((err) => {
			PMS.Log.error("FlowsInitialize", err.message);
		});
	},
	nextYear: +localStorage.getItem('nextYear'),
	currentYear: +localStorage.getItem('currentYear'),
	pastYear: +localStorage.getItem('pastYear'),
}

const isEnglishFormat = () => {
	return Dates.getDateFormat() !== "DD/MM/YYYY";
}

export default Dates;
