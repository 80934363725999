const URL = {
	getIdFromURL: (pathname) => {
		let urlSplited = pathname.split("/");
		return urlSplited.length === 3 ? urlSplited[urlSplited.length - 1] : false;
	},
	getPageFromURL: (pathname) => {
		let urlSplited = pathname.split("/");
		return urlSplited[1]
	}
}

export default URL;
