 const sendLogs = () => {
	return `mutation SendLogs(
		$global: AWSJSON
		$logs: [LogInput]
	) {
		sendLogs(
			global: $global
			logs: $logs
		)
	}`;
}

export default sendLogs;
