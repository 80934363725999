const addKeyPosition = () => {
	return `mutation HREditKeyPosition(
		$name: String
		$subgroup: String
	){
		hrEditKeyPosition(
			name: $name
			subgroup: $subgroup
		)
	}`;
}

export default addKeyPosition;
