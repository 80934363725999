const finishFlow = () => {
	return `mutation flowComplete(
		$flowType: String!
		$pendency: String
	) {
		flowComplete(
			flowType: $flowType
			pendency: $pendency
		)
	}`;
}

export default finishFlow;
