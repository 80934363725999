const HRAppraisalPDP = () => {
	return `mutation hrAppraisal(
		$appraisee_id: String
		$pdps: [PDPAppraisalInput]
	) {
		hrAppraisal(
			appraisee_id: $appraisee_id
			pdps: $pdps
		)
	}`;
}

export default HRAppraisalPDP;
